import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ theme }) => {
	return (
		<footer className={"bg-black " + theme == "SimpleLight" ? "bg-neutral-100" : "bg-black"}>
			<div className="max-w-6xl mx-auto flex justify-between p-6 items-center">
				<p className="text-zinc-500 ">All Rights Reserved menuwave.sk 2024</p>
				<Link to="/">
					<img src="/svg/logo.svg" alt="logo" className="hidden md:block max-h-10" />
				</Link>
				<div className="flex flex-row">
					<a href="https://www.linkedin.com/in/tomasnemec6/">
						<img src="/svg/linkedin.svg" alt="linkedin" className="w-14 mx-2 p-1" />
					</a>
					<a href="https://www.youtube.com/">
						<img src="/svg/youtube.svg" alt="youtube" className="w-14 mx-2 p-1" />
					</a>
					<a href="https://www.instagram.com/menu.wave/">
						<img src="/svg/instagram.svg" alt="instagram" className="w-14 mx-2 p-1" />
					</a>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
