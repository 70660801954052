import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => (
	<nav className="relative flex justify-between items-center p-6 max-w-6xl mx-auto z-10">
		<img src="svg/logo.svg" alt="MenuWave" className="w-44 hover:scale-125 transform transition duration-200" />
		<div>
			<a href="#home" className="text-zinc-400 text-sm mx-4 hidden md:inline-block">
				Home
			</a>
			<a href="#features" className="text-zinc-400 text-sm mx-4 hidden md:inline-block">
				Features
			</a>
			<a href="#references" className="text-zinc-400 text-sm mx-4 hidden md:inline-block">
				References
			</a>
			<a href="#pricing" className="text-zinc-400 text-sm mx-4 hidden md:inline-block">
				Pricing
			</a>
			{localStorage.getItem("token") ? (
				<Link
					to="/dashboard"
					className="inline-block text-white font-semibold text-sm bg-gradient-to-r from-blue-400 to-purple-700 px-6 py-3 mx-4 rounded-md text-nowrap"
				>
					Dashboard
				</Link>
			) : (
				<Link
					to="/dashboard"
					className="inline-block text-white font-semibold text-sm bg-gradient-to-r from-blue-400 to-purple-700 px-6 py-3 mx-4 rounded-md text-nowrap"
				>
					Log In
				</Link>
			)}
		</div>
	</nav>
);

export default Navbar;
