import React from "react";
import { dashButton } from "../lib/Buttons";
import { Link } from "react-router-dom";

const Header = ({ user, onLogout }) => {
	return (
		<div className="flex flex-col lg:flex-row w-full justify-between px-6 md:px-10 pb-4 pt-16 md:pt-20">
			<div className="flex flex-col-reverse md:flex-row items-start md:items-end mb-8 lg:mb-0">
				<h1 className="text-5xl font-semibold">{user.businessName}</h1>
				<div className="bg-zinc-800 px-4 py-1 rounded-lg ml-0 md:ml-5 mb-2">
					{user.activeSubscription == 0 || user.activeSubscription == "none" ? (
						<h2 className="text-zinc-500">Free tier</h2>
					) : user.activeSubscription == "pro" ? (
						<h2 className="text-blue-500 font-semibold">Pro tier</h2>
					) : user.activeSubscription == "premium" ? (
						<h2 className="text-lg bg-gradient-to-bl from-green-500 to-violet-700 bg-clip-text text-transparent font-bold">
							Premium tier
						</h2>
					) : null}
				</div>
			</div>

			<div className="flex flex-row justify-between lg:justify-start">
				{user.activeSubscription == "none" ? (
					<div className="max-w-7xl lg:mx-auto pr-5">
						<div className="relative group cursor-pointer">
							<div className="absolute -inset-1 bg-gradient-to-r from-red-400 to-violet-600 rounded-lg blur opacity-100"></div>
							<div className="relative ring-1 ring-gray-900/5 rounded-lg flex items-center justify-start">
								<Link
									to="/subscription"
									className={
										dashButton + " font-bold  !bg-neutral-900 !border-neutral-900 !px-6 lg:!px-14"
									}
								>
									UPGRADE TIER
								</Link>
							</div>
						</div>
					</div>
				) : null}

				<button className={dashButton + " !border-red-600"} onClick={onLogout}>
					Logout
				</button>
			</div>
		</div>
	);
};

export default Header;
