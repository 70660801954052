import React, { useEffect, useState, useRef } from "react";
import axiosInstance from "../../api/axiosInstance";
import { useParams } from "react-router-dom";
import ThemeSimpleDark from "../ThemeSimpleDark/ThemeSimpleDark";
import ThemeSimpleLight from "../ThemeSimpleLight/ThemeSimpleLight";
import Footer from "../lib/Footer";

const CustomizeMenu = () => {
	const { menuId } = useParams();
	const [menu, setMenu] = useState({});
	const [theme, setTheme] = useState("Simple Dark");
	const [isLoading, setIsLoading] = useState(true);
	const [businessName, setBusinessName] = useState("");

	useEffect(() => {
		const fetchMenuItems = async () => {
			try {
				const res = await axiosInstance.get(`/menu/${menuId}`);

				let newMenu = res.data.menu.Categories.sort((a, b) => a.order - b.order);

				newMenu = res.data.menu.Categories.map((category) => {
					category.MenuItems = category.MenuItems.sort((a, b) => a.order - b.order);
					return category;
				});

				setMenu(res.data.menu);
				setTheme(res.data.menu.theme);
				setBusinessName(res.data.user.businessName);
			} catch (err) {
				console.error(err);
			} finally {
				setIsLoading(false);
			}
		};

		fetchMenuItems();
	}, [menuId]);

	const updateTheme = (theme) => {
		try {
			axiosInstance.put(
				`/menu/${menuId}`,
				{ theme },
				{ headers: { "X-Auth-token": localStorage.getItem("token") } }
			);
			setTheme(theme);
		} catch (err) {
			console.error(err);
		}
	};

	const handleLike = (menuItem) => {
		setMenu({
			...menu,
			Categories: menu.Categories.map((category) => ({
				...category,
				MenuItems: category.MenuItems.map((item) =>
					item.id === menuItem ? { ...item, liked: !item.liked } : item
				),
			})),
		});
	};

	return (
		<div className={"p-2 bg-neutral-900"}>
			<div className="max-w-6xl mx-auto pt-10 ">
				<h1 className="text-5xl font-semibold text-neutral-100  pb-5 mx-2">Customize Menu</h1>
				<div className="overflow-x-auto whitespace-nowrap pb-4 mb-4 no-scrollbar ">
					<button
						onClick={() => {
							updateTheme("Simple Dark");
						}}
						className="px-5 py-2 bg-neutral-700 hover:bg-neutral-600 text-white text-lg font-semibold rounded-lg mx-3 inline-block"
					>
						Simple Dark
					</button>
					<button
						onClick={() => {
							updateTheme("Simple Light");
						}}
						className="px-5 py-2 bg-neutral-200 hover:bg-neutral-400 text-black text-lg font-semibold rounded-lg mx-3 inline-block"
					>
						Simple Light
					</button>
				</div>

				<div className="divider text-xl font-semibold pb-10">Preview</div>

				<div className="rounded-xl overflow-hidden border-zinc-200 border-2">
					{theme == "Simple Dark" ? (
						<ThemeSimpleDark
							menu={menu}
							isLoading={isLoading}
							businessName={businessName}
							handleLike={handleLike}
						></ThemeSimpleDark>
					) : (
						<ThemeSimpleLight
							menu={menu}
							isLoading={isLoading}
							businessName={businessName}
							handleLike={handleLike}
						></ThemeSimpleLight>
					)}
				</div>
			</div>
			<Footer theme={theme} />
		</div>
	);
};

export default CustomizeMenu;
