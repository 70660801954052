import React from "react";

const HeroSection = () => (
	<section id="home" className="px-10 md:px-0 relative text-center py-16 z-30">
		<h1 className="text-4xl md:text-6xl font-bold mb-8 leading-tight">
			Create Online Menu
			<br />
			Easy and Fast
		</h1>
		<p className="text-zinc-500 text-lg mb-10 max-w-xl mx-auto">
			Simplify your restaurant's menu management with our user-friendly platform. Design, customize, and share
			your digital menu effortlessly.
		</p>
		<a
			href="/dashboard"
			className="text-white text-lg bg-gradient-to-r from-blue-400 to-purple-700 px-6 py-3 mx-4 my-4 rounded-md"
		>
			Get Started Today
		</a>
		<div className="mt-16">
			<img
				src="svg/dashboard.svg"
				fetchpriority="high"
				alt="Dashboard Mockup"
				className="mx-auto drop-shadow-[0_10px_25px_rgba(255,255,255,0.15)] hover:scale-105 transform transition duration-300"
			/>
		</div>
	</section>
);

export default HeroSection;
