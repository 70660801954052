import React from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa";

const MenuItem = ({ menuItem, handleLike }) => {
	let textColor = "text-gray-200";
	let isHidden = false;

	if (menuItem.modifier) {
		let modifier = {};
		// Split the string into an array of objects by comma
		menuItem.modifier.split(",").forEach((mod) => {
			let [key, value] = mod.split(":");
			modifier[key] = value;
		});

		if (modifier.hidden === "true") {
			isHidden = true;
		}

		if (modifier.color) {
			textColor = `text-${modifier.color}-500`;
		}
	}

	if (isHidden) return null;

	return (
		<div>
			<div
				className={`flex md:hidden rounded-xl p-4 bg-zinc-800 flex-col justify-evenly min-h-24 mx-auto ${textColor}`}
			>
				<div className="flex flex-row justify-between">
					<h3 className="text-xl font-semibold">{menuItem.name}</h3>
					<p className="text-gray-400">{menuItem.foodInfo}</p>
				</div>
				{menuItem.imageUrl ? (
					<img src={menuItem.imageUrl} alt={menuItem.name} className="rounded-lg my-4" />
				) : (
					<div className="mb-2"></div>
				)}
				<p className="text-gray-400 mb-1">{menuItem.description}</p>
				<div className="flex flex-row justify-between items-center">
					<p className="font-bold text-xl">€{menuItem.price.toFixed(2)}</p>
					<button onClick={() => handleLike(menuItem.id)}>
						{menuItem.liked ? (
							<FaHeart className="h-6 w-6 text-red-500"></FaHeart>
						) : (
							<FaRegHeart className="h-6 w-6 text-red-500"></FaRegHeart>
						)}
					</button>
				</div>
			</div>
			<div
				className={`hidden md:flex rounded-xl p-4 bg-zinc-800 flex-row justify-between min-h-24 mx-auto max-w-5xl ${textColor}`}
			>
				<div className="flex flex-row justify-start">
					{menuItem.imageUrl && (
						<img
							src={menuItem.imageUrl}
							alt={menuItem.name}
							className="rounded-lg h-52 min-w-80  mr-6 object-cover object-center"
						/>
					)}
					<div className="flex flex-col justify-between">
						<div>
							<p className="text-gray-400 mb-1">{menuItem.foodInfo}</p>
							<h3 className="text-2xl font-semibold">{menuItem.name}</h3>

							<p className="text-gray-400 mb-1 text-xl">{menuItem.description}</p>
						</div>
						<p className="font-bold text-2xl">€{menuItem.price.toFixed(2)}</p>
					</div>
				</div>
				<div className="">
					<button onClick={() => handleLike(menuItem.id)}>
						{menuItem.liked ? (
							<FaHeart className="h-8 w-8 m-2 text-red-500"></FaHeart>
						) : (
							<FaRegHeart className="h-8 w-8 m-2 text-red-500"></FaRegHeart>
						)}
					</button>
				</div>
			</div>
		</div>
	);
};

export default MenuItem;
