// src/components/Dashboard/Dashboard.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../api/axiosInstance";
import Header from "./Header";
import MenuList from "./MenuList";
import MenuItemsList from "./MenuItemsList";
import MenuForm from "./MenuForm";
import QRCodePopup from "./QRCodePopup";
import { dashButton } from "../lib/Buttons";
import Footer from "./Footer";
import axios from "axios";

const Dashboard = () => {
	const [user, setUser] = useState({});
	const [menus, setMenus] = useState([]);
	const [activeMenuId, setActiveMenuId] = useState(null);
	const [qrcode, setQrCode] = useState("");

	const [editMode, setEditMode] = useState(false);
	const [currentItemId, setCurrentItemId] = useState(null);
	const [newCategoryName, setNewCategoryName] = useState("");
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [formData, setFormData] = useState({
		name: "",
		description: "",
		price: "",
		categoryId: "",
		imageUrl: "",
		modifier: "",
		foodInfo: "",
	});

	useEffect(() => {
		setFormData((prevFormData) => ({
			...prevFormData,
			menuId: activeMenuId,
		}));
	}, [activeMenuId]);

	useEffect(() => {
		const getQRCode = async () => {
			try {
				const res = await axiosInstance.get("/qrcode", {
					headers: { "x-auth-token": getToken() },
				});
				setQrCode(res.data.qrCodeUrl);
			} catch (err) {
				console.error(err);
				// Handle errors as needed
			}
		};

		const fetchMenuData = async () => {
			try {
				const res = await axiosInstance.get("/", {
					headers: { "x-auth-token": getToken() },
				});

				const data = res.data;

				setUser(data);
				setMenus(data.Menus);
				setActiveMenuId(data.activeMenuId);
			} catch (err) {
				console.error("Failed to fetch menu data:", err);
			}
		};

		getQRCode();
		fetchMenuData();
	}, []);

	//handles
	const handleDownload = () => {
		setIsPopupOpen(true);
	};

	const handleLogout = () => {
		localStorage.removeItem("token");
		window.location.href = "/";
	};

	const handleEditClick = (menuItem) => {
		setEditMode(true);
		setCurrentItemId(menuItem.id);
		setFormData({
			name: menuItem.name,
			description: menuItem.description,
			price: Number(menuItem.price).toFixed(2),
			categoryId: menuItem.categoryId,
			imageUrl: menuItem.imageUrl,
			modifier: menuItem.modifier,
			foodInfo: menuItem.foodInfo,
		});
		// Scroll to edit form
		document.getElementById("main-form").scrollIntoView({ behavior: "smooth" });
	};

	const handleCancelEdit = () => {
		setEditMode(false);
		setCurrentItemId(null);
		setFormData({
			name: "",
			description: "",
			price: "",
			categoryId: "",
			imageUrl: "",
			modifier: "",
			foodInfo: "",
		});
	};

	const handleDeleteMenuItem = async (menuItemId) => {
		const confirmDelete = window.confirm("Are you sure you want to delete this menu item?");
		if (confirmDelete) {
			try {
				await axiosInstance.delete(`/menu/item/${menuItemId}`, {
					headers: { "x-auth-token": getToken() },
				});

				//delete from state
				setMenus(
					menus.map((menu) => ({
						...menu,
						Categories: menu.Categories.map((category) => ({
							...category,
							MenuItems: category.MenuItems.filter((item) => item.id !== menuItemId),
						})),
					}))
				);
			} catch (err) {
				console.error(err);
				// Handle errors as needed
			}
		}
	};

	const handleAddMenu = async () => {
		if (user.activeSubscription == "none") {
			alert("You need to upgrade to a paid plan to add more menus.");
			return;
		}

		const menuName = prompt("Enter the name of the new menu:");
		if (!menuName) return;

		try {
			const res = await axiosInstance.post(
				"/menu",
				{ name: menuName },
				{
					headers: { "x-auth-token": getToken() },
				}
			);

			setMenus([
				...menus,
				{
					id: res.data.id,
					name: res.data.name,
					Categories: [],
				},
			]);
		} catch (err) {
			console.error(err);
			// Handle errors as needed
		}
	};

	const handleAddCategory = async (e) => {
		e.preventDefault();

		try {
			const res = await axiosInstance.post(
				"/categories",
				{ name: newCategoryName, menuId: activeMenuId },
				{
					headers: { "x-auth-token": getToken() },
				}
			);

			setMenus(
				menus.map((menu) =>
					menu.id == activeMenuId
						? {
								...menu,
								Categories: [...menu.Categories, res.data],
						  }
						: menu
				)
			);

			setNewCategoryName(""); // Clear the form field
		} catch (err) {
			console.error(err);
			// Handle errors
		}
	};

	const handleDeleteMenu = async (menuId) => {
		const confirmDelete = window.confirm("Are you sure you want to delete this menu?");
		if (confirmDelete) {
			try {
				await axiosInstance.delete(`/menu/${menuId}`, {
					headers: { "x-auth-token": getToken() },
				});

				setMenus(menus.filter((menu) => menu.id !== menuId));
			} catch (err) {
				console.error(err);
				// Handle errors as needed
			}
		}
	};

	const handleSwitchActiveMenu = async () => {
		try {
			await axiosInstance.put(
				"/menu/switch",
				{ menuId: activeMenuId },
				{
					headers: { "x-auth-token": getToken() },
				}
			);

			setUser({
				...user,
				activeMenuId: activeMenuId,
			});
		} catch (err) {
			console.error(err);
			// Handle errors as needed
		}
	};

	const handleSwitchMenu = async (menuId) => {
		try {
			setActiveMenuId(menuId);
			setMenus(
				menus.map((menu) => ({
					...menu,
					active: menu.id == menuId,
					Categories: menu.Categories.map((cat) => ({
						...cat,
						isOpen: false,
					})),
				}))
			);
		} catch (err) {
			console.error(err);
			// Handle errors as needed
		}
	};

	const handleCategorySwitch = async (categoryId, direction) => {
		try {
			// Find the index of the category we want to move
			const targetCatIndex = menus
				.find((menu) => menu.id == activeMenuId)
				.Categories.findIndex((cat) => cat.id == categoryId);

			// Determine the index of the category to switch with
			let swapWithIndex;
			if (direction == "up" && targetCatIndex > 0) {
				swapWithIndex = targetCatIndex - 1;
			} else if (
				direction == "down" &&
				targetCatIndex < menus.find((menu) => menu.id == activeMenuId).Categories.length - 1
			) {
				swapWithIndex = targetCatIndex + 1;
			} else {
				return;
			}

			const swapWithCategory = menus.find((menu) => menu.id == activeMenuId).Categories[swapWithIndex];
			const targetCategory = menus.find((menu) => menu.id == activeMenuId).Categories[targetCatIndex];

			const openCats = menus.map((menu) => ({
				menuId: menu.id,
				openCategories: menu.Categories.filter((cat) => cat.isOpen).map((cat) => cat.id),
			}));

			const res = await axiosInstance.put(
				"/categories/reorder",
				{
					categoryIds: [targetCategory.id, swapWithCategory.id],
					openCats: openCats,
				},
				{
					headers: { "x-auth-token": getToken() },
				}
			);

			// update state
			setMenus(
				menus.map((menu) =>
					menu.id == activeMenuId
						? {
								...menu,
								Categories: menu.Categories.map((cat) =>
									cat.id == targetCategory.id
										? { ...cat, order: swapWithCategory.order }
										: cat.id == swapWithCategory.id
										? { ...cat, order: targetCategory.order }
										: cat
								),
						  }
						: menu
				)
			);

			console.log(res.data.msg);
			window.location.reload();
		} catch (error) {
			console.error("Error switching categories:", error);
		}
	};

	const handleDeleteCategory = async (categoryId) => {
		const confirmDelete = window.confirm("Are you sure you want to delete this category?");
		if (confirmDelete) {
			try {
				axiosInstance.delete(`/categories/${categoryId}`, {
					headers: { "x-auth-token": getToken() },
				});

				//delete from state
				setMenus(
					menus.map((menu) => ({
						...menu,
						Categories: menu.Categories.filter((cat) => cat.id != categoryId),
					}))
				);
			} catch (err) {
				console.error(err);
				// Handle errors as needed
			}
		}
	};

	const onDragEnd = async (result) => {
		if (!result.destination || result.source.droppableId !== result.destination.droppableId) return;

		const { source, destination } = result;
		const category = menus
			.map((menu) => menu.Categories)
			.flat()
			.find((cat) => cat.id == source.droppableId);

		console.log(result);

		const updatedItems = reorderMenuItems(category.MenuItems, source.index, destination.index);

		try {
			await axiosInstance.put(
				"/menu/reorderItems",
				{ items: updatedItems },
				{ headers: { "x-auth-token": getToken() } }
			);

			//update state
			setMenus(
				menus.map((menu) =>
					menu.id == activeMenuId
						? {
								...menu,
								Categories: menu.Categories.map((cat) =>
									cat.id == category.id ? { ...cat, MenuItems: updatedItems } : cat
								),
						  }
						: menu
				)
			);
		} catch (err) {
			console.error("Failed to reorder items:", err);
		}
	};

	const onSubmit = async () => {
		try {
			if (editMode) {
				// Editing existing menu item
				const res = await axiosInstance.put(`/menu/item/${currentItemId}`, formData, {
					headers: { "x-auth-token": getToken() },
				});

				//update state
				setMenus(
					menus.map((menu) => ({
						...menu,
						Categories: menu.Categories.map((cat) => ({
							...cat,
							MenuItems: cat.MenuItems.map((item) => (item.id == currentItemId ? res.data : item)),
						})),
					}))
				);

				resetForm();
			} else {
				// Adding new menu item
				const res = await axiosInstance.post("/menu/addMenuItem", formData, {
					headers: { "x-auth-token": getToken() },
				});

				const newMenuItem = res.data;

				// Update state immutably to add the new menu item
				setMenus((prevMenus) =>
					prevMenus.map((menu) =>
						menu.id == activeMenuId
							? {
									...menu,
									Categories: menu.Categories.map((cat) =>
										cat.id == formData.categoryId
											? {
													...cat,
													MenuItems: [...(cat.MenuItems || []), newMenuItem],
													isOpen: true,
											  }
											: cat
									),
							  }
							: menu
					)
				);

				resetForm();
			}
		} catch (err) {
			console.error(err);
			// Handle errors as needed
		}
	};

	const reorderMenuItems = (items, sourceIndex, destinationIndex) => {
		const result = Array.from(items);
		const [removed] = result.splice(sourceIndex, 1);
		result.splice(destinationIndex, 0, removed);

		return result.map((item, index) => ({
			...item,
			order: index + 1,
		}));
	};

	const getToken = () => localStorage.getItem("token");

	const resetForm = () => {
		setFormData({
			name: "",
			description: "",
			price: "",
			categoryId: "",
			imageUrl: "",
			modifier: "",
			foodInfo: "",
		});
		setEditMode(false);
		setCurrentItemId(null);
	};

	const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

	const toggleCategoryOpen = (categoryIndex) => {
		setMenus(
			menus.map((menu) => ({
				...menu,
				Categories: menu.Categories.map((cat, index) => ({
					...cat,
					isOpen: index == categoryIndex ? !cat.isOpen : cat.isOpen,
				})),
			}))
		);
	};

	//UI

	return (
		<div className="text-white bg-neutral-900">
			<Header user={user} onLogout={handleLogout} />

			<div className="divider "></div>

			{/* Menus and Info */}
			<div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-40 px-6 md:px-10 overflow-hidden">
				<MenuList
					user={user}
					menus={menus}
					activeMenuId={activeMenuId}
					handleSwitchMenu={handleSwitchMenu}
					handleSwitchActiveMenu={handleSwitchActiveMenu}
					handleAddMenu={handleAddMenu}
					handleDeleteMenu={handleDeleteMenu}
				/>

				<div className="divider lg:hidden w-[2000px] -translate-x-[500px]"></div>

				<div className="flex flex-col items-start lg:items-end mb-3 lg:mb-6">
					<h2 className="text-xl font-semibold text-zinc-400 ">QR Code</h2>
					<a
						href={`https://menuwave.sk/menu/${user.id}`}
						className="hidden lg:block text-lg font-semibold mt-4"
					>
						MENU URL: <span className="underline">https://menuwave.sk/menu/{user.id}</span>
					</a>
					<div className="flex flex-row justify-between w-full lg:justify-end mt-6">
						<div className="flex flex-col">
							<button
								className={dashButton + "mr-4 mb-2"}
								onClick={() => alert("Customize feature coming soon!")}
							>
								Customize
							</button>
							<button className={dashButton + "mr-4 !border-green-500"} onClick={handleDownload}>
								Download
							</button>
						</div>
						<img id="QRCode" src={qrcode} alt="qrcode" className="" />
					</div>
				</div>
			</div>
			{/* QR Code Popup */}
			<QRCodePopup isOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} getToken={getToken} qrcode={qrcode} />

			<div className="divider lg:flex lg:mb-6"></div>

			<div className="px-4 md:px-10 pb-14 pt-2">
				<Link
					to={"/customizeMenu/" + activeMenuId}
					className="text-xl md:font-semibold bg-zinc-800 py-2 md:py-4 px-4 md:px-8 rounded-lg hover:bg-zinc-600"
				>
					Customize Menu
				</Link>
			</div>

			{/* Display Categories and Menu Items */}
			<MenuItemsList
				menus={menus}
				activeMenuId={activeMenuId}
				onDragEnd={onDragEnd}
				handleEditClick={handleEditClick}
				handleDeleteClick={handleDeleteMenuItem}
				toggleCategoryOpen={toggleCategoryOpen}
				handleCategoryMove={handleCategorySwitch}
				handleDeleteCategory={handleDeleteCategory}
			/>

			{/* Form to Add or Edit Menu Items */}
			<MenuForm
				formData={formData}
				onChange={onChange}
				onSubmit={onSubmit}
				editMode={editMode}
				menus={menus}
				activeMenuId={activeMenuId}
				handleCancelEdit={handleCancelEdit}
			/>

			{/* Form to Add New Category */}
			<div className="px-6 md:px-10 pb-20">
				<form onSubmit={handleAddCategory} className="">
					<h2 className="text-2xl font-semibold">Add a New Category</h2>
					<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
						<input
							type="text"
							name="newCategoryName"
							placeholder="Category Name"
							value={newCategoryName}
							onChange={(e) => setNewCategoryName(e.target.value)}
							required
							className="w-full bg-zinc-800 px-6 py-3 my-4 rounded-lg"
						/>
					</div>
					<div className="mt-4">
						<button type="submit" className={dashButton + "!border-yellow-500"}>
							Add Category
						</button>
					</div>
				</form>
			</div>

			<Footer></Footer>
		</div>
	);
};

export default Dashboard;
