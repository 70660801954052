import React, { useState } from "react";
import axiosInstance from "../../api/axiosInstance";

const Register = () => {
	const [formData, setFormData] = useState({
		businessName: "",
		email: "",
		password: "",
		password2: "",
	});

	const { businessName, email, password, password2 } = formData;

	const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = async (e) => {
		e.preventDefault();

		// check if passwords match
		if (password !== password2) {
			console.error("Passwords do not match");
			return;
		}

		try {
			const res = await axiosInstance.post("/auth/register", formData);
			localStorage.setItem("token", res.data.token);
			// Redirect to dashboard
			window.location = "/dashboard";
		} catch (err) {
			console.error(err.response.data);
		}
	};

	return (
		<div className=" bg-black text-white min-h-[100vh] w-full">
			<a href="/">
				<div className="hidden md:flex fixed top-10 left-10 justify-start content-center items-center">
					<img src="svg/logo_square.svg" alt="logo" className="mr-4 w-16" />
					<h2 className="text-2xl font-semibold">MenuWave</h2>
				</div>
			</a>

			<div className="flex flex-row justify-start min-h-[100vh] mx-auto">
				<div className="w-full md:w-1/2 flex justify-center md:justify-end">
					<div className="flex flex-col justify-center items-start py-16 content-center h-full w-[80%] md:w-[65%] md:mr-[15%]">
						<h1 className="text-6xl font-semibold ">Register</h1>
						<p className="text-zinc-500 text-xl mt-6">
							Bring your menu online in minutes. Customize, update, and share effortlessly—no technical
							skills required!
						</p>
						<form onSubmit={onSubmit} className="w-full">
							<div className="flex flex-col w-full">
								<input
									type="text"
									placeholder="Business Name"
									name="businessName"
									value={businessName}
									onChange={onChange}
									required
									className="bg-zinc-900 py-3 px-5 mt-10 rounded-md"
								/>
								<input
									type="email"
									name="email"
									placeholder="Your Email"
									value={email}
									onChange={onChange}
									required
									className="bg-zinc-900 py-3 px-5 mt-4 rounded-md"
								/>
								<input
									type="password"
									name="password"
									placeholder="Password"
									value={password}
									onChange={onChange}
									required
									className="bg-zinc-900 py-3 px-5 mt-4 rounded-md"
								/>
								<input
									type="password"
									name="password2"
									placeholder="Repeat Password"
									value={password2}
									onChange={onChange}
									required
									className="bg-zinc-900 py-3 px-5 my-4 rounded-md"
								/>
							</div>
							<button
								type="submit"
								className="text-white font-semibold text-sm bg-gradient-to-r from-blue-400 to-purple-700 px-6 py-3 rounded-md text-nowrap"
								onClick={onSubmit}
							>
								Signup Now
							</button>
							<br className="md:hidden" />
							<a
								href="/login"
								className="block md:inline-block mt-4 text-zinc-500 text-sm md:mx-4 underline"
							>
								Already have an account? Log in
							</a>
						</form>
					</div>
				</div>
			</div>
			<img
				src="svg/menu_wall.png"
				alt="menu wall"
				className="hidden md:block fixed top-0 right-10 h-[220vh] w-[50vw] -translate-y-[60vh] object-contain"
			/>
		</div>
	);
};

export default Register;
