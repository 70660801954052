import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import axiosInstance from "../../api/axiosInstance";

const Subscription = () => {
	const stripe = useStripe();
	const elements = useElements();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState({});

	useEffect(() => {
		// Fetch user data
		const token = localStorage.getItem("token");

		const fetchUser = async () => {
			try {
				const res = await axiosInstance.get("/users/whoami", {
					headers: {
						"X-Auth-Token": token,
					},
				});
				setUser(res.data);
			} catch (error) {
				console.error("Error fetching user:", error);
			}
		};

		fetchUser();
	}, []);

	const handleSubscription = async (event) => {
		event.preventDefault();
		setLoading(true);

		const cardElement = elements.getElement(CardElement);

		// Create payment method
		const { paymentMethod, error } = await stripe.createPaymentMethod({
			type: "card",
			card: cardElement,
		});

		if (error) {
			console.error(error);
			setLoading(false);
			return;
		}

		try {
			// Call backend to create subscription
			const res = await axiosInstance.post("/stripe/create-subscription", {
				user: user,
				paymentMethodId: paymentMethod.id,
				priceId: "price_1PzpEmAW84qoSEfmq26Q761J", // Pro plan price ID
			});

			// Confirm payment with client secret
			const { error: confirmError } = await stripe.confirmCardPayment(res.data.clientSecret);

			if (confirmError) {
				console.error(confirmError);
				setLoading(false);
				return;
			}

			// Payment succeeded
			alert("Subscription successful! Your plan is now Pro.");
			navigate("/dashboard");
		} catch (error) {
			console.error("Subscription creation failed:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="min-h-screen bg-gray-100 flex items-center justify-center">
			<div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
				<h2 className="text-2xl font-semibold mb-6 text-gray-800">Subscribe to Pro Plan</h2>

				<form onSubmit={handleSubscription} className="space-y-6">
					<div className="p-4 border border-gray-300 rounded-lg bg-gray-50">
						<CardElement className="p-2" />
					</div>

					<button
						type="submit"
						disabled={!stripe || loading}
						className={`w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-500 transition duration-300 ${
							loading ? "opacity-50 cursor-not-allowed" : ""
						}`}
					>
						{loading ? "Processing..." : "Subscribe to Pro"}
					</button>
				</form>

				{/* Loading Spinner */}
				{loading && (
					<div className="flex justify-center mt-4">
						<div className="loader border-t-4 border-indigo-600 w-8 h-8 rounded-full animate-spin"></div>
						<p className="ml-2 text-indigo-600">Processing...</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default Subscription;
