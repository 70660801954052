import React from "react";
import { dashButton } from "../lib/Buttons";
import { FaFolderPlus } from "react-icons/fa";

const MenuList = ({
	user,
	menus,
	activeMenuId,
	handleSwitchMenu,
	handleSwitchActiveMenu,
	handleAddMenu,
	handleDeleteMenu,
}) => {
	return (
		<div className="flex flex-col">
			<div className="flex items-center pb-3">
				<h2 className="text-xl font-semibold  text-zinc-400 pr-10">All menus</h2>
				<button onClick={handleAddMenu} className={dashButton + ""}>
					<FaFolderPlus></FaFolderPlus>
				</button>
			</div>
			{menus.map((menu) => (
				<div key={menu.id} className="flex justify-between lg:pr-20">
					<div className="flex flex-col justify-between py-2 md:mr-20">
						<button onClick={() => handleSwitchMenu(menu.id)}>
							<h2
								className={`text-4xl font-semibold ${
									menu.id == activeMenuId ? "text-blue-400" : "text-white"
								}`}
							>
								{menu.name}
							</h2>
						</button>
					</div>
					{menu.id == user.activeMenuId ? (
						// Both conditions true (menu.id matches both activeMenuId and user.activeMenuId)
						<div className="flex items-center content-center">
							<button className={dashButton + "!bg-[#46A5E9]"}>Active</button>
						</div>
					) : menu.id == activeMenuId && menu.id !== user.activeMenuId ? (
						// Condition when menu.id matches activeMenuId but NOT user.activeMenuId
						<div className="flex items-center content-center">
							<button className={dashButton + ""} onClick={handleSwitchActiveMenu}>
								Make Active
							</button>
						</div>
					) : (
						// Default case
						<div className="flex items-center content-center">
							<button
								onClick={() => handleDeleteMenu(menu.id)}
								// className={dashButton + "!border-red-600 !bg-red-600"}
								className="font-semibold text-red-500 text-xl px-6 py-2"
							>
								Delete
							</button>
						</div>
					)}
				</div>
			))}
		</div>
	);
};

export default MenuList;
