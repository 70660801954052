import React from "react";

const BackgroundElements = () => (
	<div className="absolute top-0 left-0 w-full h-full z-0">
		<div className="relative max-w-6xl mx-auto">
			<img
				src="svg/background_circle.svg"
				alt="circle"
				className="absolute top-28 left-1/2 transform -translate-x-1/2 -translate-y-1/2w-1/2  opacity-80"
			/>
			<img
				src="svg/elipse.svg"
				alt="circle"
				className="absolute opacity-50 md:opacity-100 w-30 -rotate-90 top-96"
			/>
			<img
				src="svg/elipse.svg"
				alt="circle"
				className="absolute opacity-40 md:opacity-100 w-30 rotate-45 top-12 left-1/2 w-32"
			/>
			<img
				src="svg/elipse.svg"
				alt="circle"
				className="absolute opacity-30 md:opacity-100 w-30 rotate-45 top-80 right-8 w-32"
			/>
		</div>
		<div className="absolute top-0 left-0 w-full h-full flex justify-evenly">
			<img src="svg/background_line.svg" alt="line" className="opacity-70 -translate-y-[400px] h-[3000px]" />
			<img src="svg/background_line.svg" alt="line" className="opacity-80 -translate-y-[400px] h-[3000px]" />
			<img src="svg/background_line.svg" alt="line" className="hidden md:block opacity-80" />
			<img src="svg/background_line.svg" alt="line" className="hidden md:block opacity-90 " />
			<img src="svg/background_line.svg" alt="line" className="hidden md:block" />
			<img src="svg/background_line.svg" alt="line" className="hidden md:block" />
			<img src="svg/background_line.svg" alt="line" className="hidden md:block" />
			<img src="svg/background_line.svg" alt="line" className="hidden md:block" />
			<img src="svg/background_line.svg" alt="line" className="opacity-80 -translate-y-[400px] h-[3000px]" />
			<img src="svg/background_line.svg" alt="line" className="opacity-80 -translate-y-[400px] h-[3000px]" />
			<img src="svg/background_line.svg" alt="line" className="opacity-70 -translate-y-[400px] h-[3000px]" />
		</div>
	</div>
);

export default BackgroundElements;
