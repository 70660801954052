import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Subscription from "./components/subscription/Subscription";
import Dashboard from "./components/dashboard/Dashboard";
import Menu from "./components/menu/Menu";
import Home from "./components/home/Home";
import CustomizeMenu from "./components/customizeMenu/CustomizeMenu";
import ProtectedRoute from "./components/lib/ProtectedRoute";
import Error404 from "./components/Error404";

// stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Home />} />
				{/* auth */}
				<Route path="/login" element={<Login />} />
				<Route path="/register" element={<Register />} />

				<Route
					path="/subscription"
					element={
						<Elements stripe={stripePromise}>
							<Subscription /> {/* The component using useStripe must be wrapped inside <Elements> */}
						</Elements>
					}
				/>
				{/* admin */}
				<Route path="/dashboard" element={<ProtectedRoute element={Dashboard} />} />
				<Route path="/customizemenu/:menuId" element={<ProtectedRoute element={CustomizeMenu} />} />
				{/* public */}
				<Route path="/menu/:userId" element={<Menu />} />
				{/* Additional routes */}

				{/* 404 */}
				<Route path="*" element={<Error404 />} />
			</Routes>
		</Router>
	);
}

export default App;
