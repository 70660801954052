import React from "react";

const ContactForm = () => (
	<section id="contact">
		<div className="relative flex px-8 pb-10 max-w-6xl mx-auto">
			<img src="svg/planet.svg" alt="planets" className="hidden md:block md:w-[40%] w-[65%] mx-12 my-4" />
			<div className="flex-grow flex flex-col justify-center items-start">
				<h2 className="text-4xl font-semibold leading-[2.8rem]">Get In Touch</h2>
				<p className="mb-8 mt-4 text-zinc-500">
					Reach out for assistance, questions, or to learn how we can enhance your restaurant's digital menu
					experience!
				</p>
				<input
					type="email"
					placeholder="Your Email"
					className="w-full md:w-4/5 bg-zinc-800 px-6 py-3 mb-6 rounded-lg"
				/>
				<input
					type="text"
					placeholder="Name"
					className="w-full md:w-4/5 bg-zinc-800 px-6 py-3 mb-6 rounded-lg"
				/>
				<textarea
					placeholder="Message"
					rows={5}
					className="w-full md:w-4/5 bg-zinc-800 px-6 py-3 mb-8 rounded-lg text-top"
				/>
				<button className="text-white text-lg bg-gradient-to-r from-blue-400 to-purple-700 px-6 py-3 rounded-md">
					Get in touch
				</button>
			</div>
		</div>
	</section>
);

export default ContactForm;
