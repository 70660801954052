import React from "react";
import { dashButton } from "../lib/Buttons";

const MenuForm = ({ formData, onChange, onSubmit, editMode, menus, activeMenuId, handleCancelEdit }) => {
	const { name, description, price, categoryId, imageUrl, modifier, foodInfo } = formData;

	const activeMenu = menus.find((menu) => menu.id == activeMenuId);

	if (!activeMenu) {
		return <p>No menu found</p>; // Or handle the missing menu case however you like
	}

	return (
		<div className="p-6 lg:p-10">
			<div id="main-form">
				<h2 className="text-2xl font-semibold mb-4">{editMode ? "Edit Menu Item" : "Add a New Menu Item"}</h2>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
					<input
						type="text"
						name="name"
						placeholder="Name"
						value={name}
						onChange={onChange}
						required
						className="w-full bg-zinc-800 px-6 py-3 rounded-lg"
					/>
					<input
						type="number"
						name="price"
						placeholder="Price"
						value={price}
						onChange={onChange}
						required
						className="w-full bg-zinc-800 px-6 py-3 rounded-lg"
						step="0.01"
					/>

					{/* Dropdown for categories */}
					<select
						name="categoryId"
						value={categoryId}
						onChange={onChange}
						className="w-full bg-zinc-800 px-6 py-3 rounded-lg min-h-12"
						required
					>
						<option value="">Select Category</option>
						{activeMenu.Categories.map((category) => (
							<option key={category.id} value={category.id}>
								{category.name}
							</option>
						))}
					</select>

					<input
						type="text"
						name="imageUrl"
						placeholder="Image URL"
						value={imageUrl}
						onChange={onChange}
						className="w-full bg-zinc-800 px-6 py-3 rounded-lg"
					/>
				</div>
				<textarea
					name="description"
					placeholder="Description"
					value={description}
					onChange={onChange}
					className="w-full bg-zinc-800 px-6 py-3 mt-4 rounded-lg"
				/>
				<textarea
					name="foodInfo"
					placeholder="Info about food"
					value={foodInfo}
					onChange={onChange}
					className="w-full bg-zinc-800 px-6 py-3 mt-2 rounded-lg"
				/>
				<textarea
					name="modifier"
					placeholder="Modifiers"
					value={modifier}
					onChange={onChange}
					className="w-full bg-zinc-800 px-6 py-3 my-2 rounded-lg"
				/>
				<div className="">
					<button onClick={onSubmit} className={dashButton + "!border-violet-600"}>
						{editMode ? "Update Menu Item" : "Add Menu Item"}
					</button>
					{editMode && (
						<button
							type="button"
							onClick={handleCancelEdit}
							className={dashButton + "!border-zinc-600 ml-4 !font-medium"}
						>
							Cancel
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default MenuForm;
