import React from "react";

const Footer = () => (
	<footer className="bg-zinc-900">
		<div className="px-4 flex flex-col md:flex-row max-w-6xl mx-auto pt-16 pb-8">
			<div className="flex-1">
				<img src="svg/logo_simple.svg" alt="" />
				<p className="text-zinc-500 py-5 pr-10">
					MenuWave helps restaurants easily create, customize, and publish digital menus, offering a seamless
					solution for modernizing dining experiences online.
				</p>
			</div>
			<div className="hidden md:flex flex-1 flex-col items-center justify-evenly pt-4">
				<h3 className="text-center font-semibold text-lg">Sections</h3>
				<a href="#home" className="text-zinc-500">
					Home
				</a>
				<a href="#features" className="text-zinc-500">
					Features
				</a>
				<a href="#references" className="text-zinc-500">
					References
				</a>
				<a href="#pricing" className="text-zinc-500">
					Pricing
				</a>
			</div>
			<div className="flex-1 flex flex-col justify-evenly items-end pt-4">
				<h3 className="text-right font-semibold text-lg">Company Info</h3>
				<p className="text-zinc-500">Adresa 123, Bratislava Slovakia</p>
				<p className="text-zinc-500">ICO: 12345678</p>
				<p className="text-zinc-500">DIC: 12345678</p>
			</div>
		</div>
		<div className="max-w-6xl mx-auto flex justify-between p-6 items-center">
			<p className="text-zinc-500">All Rights Reserved menuwave.sk 2024</p>
			<div className="flex flex-row">
				<a href="https://www.linkedin.com/in/tomasnemec6/">
					<img src="svg/linkedin.svg" alt="linkedin" className="w-14 mx-2 p-1" />
				</a>
				<a href="https://www.youtube.com/">
					<img src="svg/youtube.svg" alt="youtube" className="w-14 mx-2 p-1" />
				</a>
				<a href="https://www.instagram.com/menu.wave/">
					<img src="svg/instagram.svg" alt="instagram" className="w-14 mx-2 p-1" />
				</a>
			</div>
		</div>
	</footer>
);

export default Footer;
