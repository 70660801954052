import React from "react";
import { useRef } from "react";
import MenuItem from "./MenuItemSimpleDark";

const ThemeSimpleDark = ({ menu = { Categories: [] }, isLoading = true, businessName = "", handleLike }) => {
	const categoryRefs = useRef([]);

	const scrollToCategory = (index) => {
		if (categoryRefs.current[index]) {
			categoryRefs.current[index].scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<div className="bg-neutral-900">
			<div className="px-2 py-4 md:py-8 max-w-6xl mx-auto">
				<div className="flex-col md:flex-row flex justify-between">
					<h1 className="text-4xl md:text-5xl font-semibold p-4 text-left">{businessName}</h1>
					<div className="flex pl-3">
						<img src="/svg/linkedin.svg" alt="linkedin" className="w-10 lg:w-16 mx-1 lg:mx-2 lg:p-1 " />
						<img src="/svg/instagram.svg" alt="linkedin" className="w-10 lg:w-16 mx-1 lg:mx-2 lg:p-1" />
						<img src="/svg/youtube.svg" alt="linkedin" className="w-10 lg:w-16 mx-1 lg:mx-2 lg:p-1" />
					</div>
				</div>

				<div className="divider"></div>

				{/* Horizontal Scrollable Category Bar */}
				{menu?.Categories?.length > 0 && (
					<div className="overflow-x-auto whitespace-nowrap pb-4 mb-4 no-scrollbar">
						{menu.Categories.map((category, index) => (
							<button
								key={category.id}
								onClick={() => scrollToCategory(index)}
								className="px-4 py-2 bg-zinc-800 hover:bg-zinc-700 text-white rounded-lg mx-2 inline-block"
							>
								{category.name}
							</button>
						))}
					</div>
				)}

				{isLoading ? (
					<div className="text-center py-20">
						<div
							className="spinner-border animate-spin inline-block w-10 h-10 border-4 rounded-full"
							role="status"
						>
							<span className="sr-only">Loading...</span>
						</div>
						<p className="mt-4 text-lg">Loading menu...</p>
					</div>
				) : menu?.Categories?.length > 0 ? (
					menu.Categories.map((category, index) => (
						<div
							key={category.id}
							className="mb-6 py-4 px-2 rounded-3xl max-w-5xl mx-auto"
							ref={(el) => (categoryRefs.current[index] = el)} // Assign each category div to the ref
						>
							<h2 className="text-2xl lg:text-3xl font-semibold mb-4">{category.name}</h2>
							<div className="grid grid-cols-1 gap-4 mb-3">
								{category.MenuItems.map((menuItem) => (
									<MenuItem key={menuItem.id} menuItem={menuItem} handleLike={handleLike} />
								))}
							</div>
						</div>
					))
				) : (
					<p className="text-center text-lg p-4 mb-2 font-semibold">No menu items available</p>
				)}
			</div>
		</div>
	);
};

export default ThemeSimpleDark;
