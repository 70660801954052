import { Link } from "react-router-dom";

function PricingCard(imageSrc, title, features, price, isYearly, glow) {
	if (glow) {
		return (
			<div key={title} className="max-w-7xl mx-auto px-4 lg:px-0">
				<img src={"svg/" + imageSrc} alt="tier1" className="relative w-20 translate-y-[50%] mx-auto z-50" />
				<div className="relative group cursor-pointer">
					<div className="absolute -inset-1 bg-gradient-to-r from-red-400 to-violet-600 rounded-2xl blur opacity-100 "></div>
					<div className="relativ ring-1 ring-gray-900/5 rounded-2xl leading-none flex items-top justify-start space-x-6">
						<div className="space-y-2">
							<div className="relative mx-auto max-w-md rounded-2xl bg-gradient-to-tr from-pink-300 to-blue-300 p-0.5 shadow-lg">
								<div className="rounded-2xl">
									{/* my widget */}
									<div className="flex flex-col items-center min-w-[85vw] lg:min-w-0 lg:w-full mx-auto px-0">
										<div className="bg-zinc-900 w-full mx-4 flex flex-col items-center rounded-2xl px-6">
											<h2 className="pt-12 text-lg font-semibold">{title}</h2>
											<div className="divider"></div>
											{features.map((feature, index) => {
												const padding = index == 0 ? "" : "pt-4";

												return (
													<div
														key={feature}
														className={`flex flex-row items-center w-full ${padding}`}
													>
														<img src="svg/check.svg" alt="checkmark" />
														<p className="text-white pl-4">{feature}</p>
													</div>
												);
											})}
											<div className="divider"></div>
											<div className="flex flex-row justify-between items-center w-full pb-6">
												<h1 className="text-zinc-500">
													<span className="text-4xl text-white font-semibold">{price}€ </span>
													{isYearly ? "/yr" : "/mo"}
												</h1>
												<Link
													to={"/signup?tier=" + title}
													className="inline-block text-white text-sm bg-gradient-to-r from-blue-400 to-purple-700 px-6 py-3 rounded-md text-nowrap"
												>
													Signup Now
												</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div key={title} className="flex flex-col items-center min-w-[85vw] lg:min-w-0 lg:w-full mx-auto px-4 lg:px-0">
			<img src={"svg/" + imageSrc} alt="tier1" className="w-20 translate-y-[50%]" />
			<div className="bg-zinc-900 w-full mx-4 flex flex-col items-center rounded-2xl px-6">
				<h2 className="pt-12 text-lg font-semibold">{title}</h2>
				<div className="divider"></div>
				{features.map((feature, index) => {
					const padding = index == 0 ? "" : "pt-4";

					return (
						<div key={feature} className={`flex flex-row items-center w-full ${padding}`}>
							<img src="svg/check.svg" alt="checkmark" />
							<p className="text-white pl-4">{feature}</p>
						</div>
					);
				})}
				<div className="divider"></div>
				<div className="flex flex-row justify-between items-center w-full pb-6">
					{price == 0 ? (
						<h1 className="text-zinc-500">
							<span className="text-4xl text-white font-semibold">Free</span>
						</h1>
					) : (
						<h1 className="text-zinc-500">
							<span className="text-4xl text-white font-semibold">{price}€</span>
							{isYearly ? "/yr" : "/mo"}
						</h1>
					)}

					<a
						href={"/signup?tier=" + title}
						className="inline-block text-white text-sm bg-gradient-to-r from-blue-400 to-purple-700 px-6 py-3 rounded-md text-nowrap"
					>
						Signup Now
					</a>
				</div>
			</div>
		</div>
	);
}

export default PricingCard;
