import React, { useEffect, useState, useRef } from "react";
import axiosInstance from "../../api/axiosInstance";
import { useParams } from "react-router-dom";
import ThemeSimpleDark from "../ThemeSimpleDark/ThemeSimpleDark";
import ThemeSimpleLight from "../ThemeSimpleLight/ThemeSimpleLight";
import Footer from "../lib/Footer";

const Menu = () => {
	const { userId } = useParams();
	const [menu, setMenu] = useState({});
	const [businessName, setBusinessName] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	const categoryRefs = useRef([]);

	useEffect(() => {
		const fetchMenuItems = async () => {
			try {
				const res = await axiosInstance.get(`/menu/user/${userId}`);
				let menu = res.data.menu;

				if (Array.isArray(menu.Categories)) {
					menu.Categories.sort((a, b) => a.order - b.order);

					menu.Categories.forEach((category) => {
						category.MenuItems = (category.MenuItems || []).sort((a, b) => a.order - b.order);
					});
					setMenu(menu);
				} else {
					console.error("Categories data is not an array");
					setMenu([]);
				}

				setBusinessName(res.data.businessName || "");
			} catch (err) {
				console.error(err);
			} finally {
				setIsLoading(false);
			}
		};

		fetchMenuItems();
	}, [userId]);

	const handleLike = (menuItem) => {
		setMenu({
			...menu,
			Categories: menu.Categories.map((category) => ({
				...category,
				MenuItems: category.MenuItems.map((item) =>
					item.id === menuItem ? { ...item, liked: !item.liked } : item
				),
			})),
		});
	};

	return (
		<div>
			{menu.theme === "Simple Dark" ? (
				<ThemeSimpleDark
					menu={menu}
					isLoading={isLoading}
					businessName={businessName}
					handleLike={handleLike}
				></ThemeSimpleDark>
			) : menu.theme === "Simple Light" ? (
				<ThemeSimpleLight
					menu={menu}
					isLoading={isLoading}
					businessName={businessName}
					handleLike={handleLike}
				></ThemeSimpleLight>
			) : (
				<div>Theme not found</div>
			)}
			<Footer></Footer>
		</div>
	);
};

export default Menu;
