import React from "react";
import FeatureCard from "./FeatureCard";

const FeaturesSection = () => (
	<div>
		<section id="features" className="relative z-10 py-16 px-4 bg-black">
			<div className="max-w-6xl mx-auto">
				<h2 className="text-5xl font-semibold mb-4 ml-4">Features</h2>
				<p className="text-zinc-500 text-lg mb-10 max-w-xl ml-4">
					Bring your menu online in minutes. Customize, update, and share effortlessly—no technical skills
					required!
				</p>
				<div className="px-1 sm:px-4 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8 mx-auto">
					{FeatureCard(
						"svg/feature1.svg",
						"Drag-and-Drop Menu Builder",
						"Create and organize your menu items with a simple drag-and-drop interface. No coding required."
					)}
					{FeatureCard(
						"svg/feature2.svg",
						"Real-Time Menu Updates",
						"Instantly update your menu from anywhere—whether you're adding new dishes or changing prices."
					)}
					{FeatureCard(
						"svg/feature3.svg",
						"Customizable Design",
						"Choose from a range of design templates or customize your own. Match your brand's look and feel with flexible color, font, and layout options."
					)}
					{FeatureCard(
						"svg/feature4.svg",
						"QR Code Generation",
						"Automatically generate a unique QR code for your online menu. Print it for your tables or share it digitally."
					)}
					{FeatureCard(
						"svg/feature5.svg",
						"Menu Analytics",
						"Track what items are most popular and gain insights into customer preferences, helping you optimize your offerings."
					)}
					{FeatureCard(
						"svg/feature6.svg",
						"Multi-Language Support",
						"Easily create and publish menus in multiple languages to cater to diverse customers."
					)}
				</div>
			</div>
			{/* planets */}
			<div className="relative max-w-6xl mx-auto flex px-4 md:px-0 pt-20 md:pt-5">
				<img
					src="svg/planets.svg"
					alt="planets"
					className="inline-block absolute right-0 bottom-0 translate-x-[50%] translate-y-20 md:transform-none md:relative md:right-auto md:bottom-auto md:w-[45%] w-[65%] mx-12 my-4 animate-pulse "
				/>
				<div className="flex-grow flex flex-col justify-center items-start z-20">
					<h2 className="text-4xl font-semibold leading-[2.6rem]">
						We're here to <br /> guide you in <br /> the digital world
					</h2>
					<p className="my-6 leading-7">
						With MenuWave, your customers can access your menu from anywhere. Real-time updates and easy QR
						code integration ensure they always have the latest offerings at their fingertips.
					</p>
					<a
						href="/dashboard"
						className="text-white  text-lg bg-gradient-to-r from-blue-400 to-purple-700 px-6 py-3 rounded-md"
					>
						Get Started Today
					</a>
				</div>
			</div>
		</section>

		{/* references section */}
		<section id="references" className="relative bg-zinc-900 z-20">
			<div className="max-w-6xl mx-auto h-[650px]">
				<h1 className="text-4xl text-center font-semibold px-6 py-10 md:p-20">
					Companies we Worked <br /> With in Since 2024
				</h1>
				<div className="grid grid-cols-2 justify-items-center gap-y-5 px-10 md:px-0 md:flex md:flex-row md:justify-between ">
					<img
						src="svg/company1.svg"
						alt="company1"
						className="p-2 hover:scale-125 transition duration-150"
					/>
					<img
						src="svg/company2.svg"
						alt="company2"
						className="p-2 hover:scale-125 transition duration-150"
					/>
					<img
						src="svg/company1.svg"
						alt="company1"
						className="p-2 hover:scale-125 transition duration-150"
					/>
					<img
						src="svg/company2.svg"
						alt="company2"
						className="p-2 hover:scale-125 transition duration-150"
					/>
					<img
						src="svg/company1.svg"
						alt="company1"
						className="p-2 hover:scale-125 transition duration-150"
					/>
					<img
						src="svg/company2.svg"
						alt="company2"
						className="p-2 hover:scale-125 transition duration-150"
					/>
				</div>
				<div className="relative bg-gradient-to-r from-indigo-700 to-purple-800 md:from-blue-300 md:to-purple-800 w-[90%] mx-auto  rounded-[35px] mt-10 p-10 md:p-20 md:mt-[100px] lg:w-full overflow-hidden">
					<p className="relative z-10 text-lg">Love our Our Tool?</p>
					<h1 className="relative z-10 text-4xl font-semibold mt-4 mb-10 leading-[3rem] ">
						Fell Free to Join our <br /> 15 Days Free Trial
					</h1>
					<a
						href="/dashboard"
						className="relative z-10 text-white text-lg bg-black px-6 py-3 rounded-md text-nowrap"
					>
						Get Started Today
					</a>
					<img src="svg/world.svg" alt="world" className="absolute right-0 top-0" />
				</div>
			</div>
		</section>
	</div>
);

export default FeaturesSection;
