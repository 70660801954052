import React from "react";
import Popup from "reactjs-popup";
import axiosInstance from "../../api/axiosInstance";

const QRCodePopup = ({ isOpen, setIsPopupOpen, getToken }) => {
	const downloadQrCode = async (color) => {
		try {
			// Send request to get QR code with color
			const res = await axiosInstance.get("/qrcode", {
				headers: { "x-auth-token": getToken() },
				params: { color: color },
			});

			// Check if the request was successful
			if (res.status !== 200 || !res.data.qrCodeUrl) {
				console.error("Failed to download QR code");
				return;
			}

			// Extract the QR code URL from the response
			const qrCodeUrl = res.data.qrCodeUrl;

			// Create a temporary anchor element to trigger download
			const link = document.createElement("a");
			link.href = qrCodeUrl;
			link.download = "qr-code.png";
			document.body.appendChild(link); // Append link to body
			link.click(); // Trigger the download
			document.body.removeChild(link); // Remove link after download

			// Close the popup after downloading
			setIsPopupOpen(false);
		} catch (error) {
			console.error("Error while downloading QR code:", error);
		}
	};

	return (
		<Popup open={isOpen} onClose={() => setIsPopupOpen(false)} modal className="p-4">
			<div className="bg-zinc-800 p-8 rounded-lg drop-shadow-[0_0_40px_rgba(255,255,255,0.10)]">
				<h2 className="text-lg font-semibold text-white">Select QR Code Color</h2>
				<div className="">
					<p>Please choose the color for your QR code:</p>
				</div>
				<div className="flex justify-around">
					<button
						onClick={() => downloadQrCode("black")}
						className="bg-black text-white font-semibold rounded-md px-5 py-3 mt-4"
					>
						Black
					</button>
					<button
						onClick={() => downloadQrCode("white")}
						className="bg-white text-black font-semibold rounded-md px-5 py-3 mt-4"
					>
						White
					</button>
				</div>
			</div>
		</Popup>
	);
};

export default QRCodePopup;
