import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import HeroSection from "./HeroSection";
import FeaturesSection from "./FeaturesSection";
import PricingSection from "./PricingSection";
import ContactForm from "./ContactForm";
import Footer from "./Footer";
import BackgroundElements from "./BackgroundElements";
import { Helmet } from "react-helmet";

const Home = () => {
	const [isYearly, setIsYearly] = useState(false);

	const togglePlan = () => setIsYearly(!isYearly);

	const tiers = {
		"Free Tier": {
			src: "tier1.svg",
			price: 2,
			yearPrice: 24,
			features: ["QR Code Menu", "Basic Customization Options", "Multiple Designs", "Email Support"],
		},
		"Pro Tier": {
			src: "tier2.svg",
			price: 10,
			yearPrice: 110,
			features: [
				"Web site of the restaurant",
				"Multi-Language Support",
				"Custom QR Code Generation",
				"Priority Email Support",
			],
		},
		"Premium Tier": {
			src: "tier3.svg",
			price: 25,
			yearPrice: 250,
			features: [
				"Unlimited Menus",
				"Marketing tools and Analytics",
				"Custom Branding",
				"Contactless QR Table Ordering",
			],
		},
	};

	return (
		<div className="bg-black text-white overflow-x-hidden">
			<Helmet>
				<link rel="preload" href="svg/dashboard.svg" as="image" type="image/svg+xml" />
			</Helmet>
			<BackgroundElements />
			<Navbar />
			<HeroSection />
			{/* Wave Section */}
			<img
				src="svg/wave.svg"
				fetchpriority="high"
				alt="wave"
				className="hidden md:block relative z-20 w-full min-w-[1000pxh] 2xl:scale-y-90 -mt-[270px] lg:-mt-[320px] xl:-mt-[380px] 2xl:-mt-[480px]"
			/>
			<div className="relative z-10 bg-black w-full h-40 -mt-52"></div>
			<FeaturesSection />
			<PricingSection tiers={tiers} isYearly={isYearly} togglePlan={togglePlan} />
			<ContactForm />
			<Footer />
		</div>
	);
};

export default Home;
