function FeatureCard(imageSrc, title, description) {
	return (
		<div className="bg-zinc-900 p-4 rounded-3xl">
			<img src={imageSrc} alt="box" className="mx-auto my-2 md:my-12 w-20 md:w-28" />
			<h3 className="text-xl font-semibold text-center">{title}</h3>
			<p className="text-zinc-500 text-xs mx-auto text-center mt-3 md:mt-6 mb-3 md:mb-8 px-4">{description}</p>
		</div>
	);
}

export default FeatureCard;
