import React from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { dashButton } from "../lib/Buttons";
import { FaExpandAlt, FaCompressAlt, FaChevronUp, FaChevronDown } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const MenuItemsList = ({
	menus = [],
	activeMenuId = null,
	onDragEnd = () => {},
	handleEditClick = () => {},
	handleDeleteClick = () => {},
	toggleCategoryOpen = () => {},
	handleCategoryMove = () => {},
	handleDeleteCategory = () => {},
}) => {
	const activeMenu = menus.find((menu) => menu.id == activeMenuId);

	if (!activeMenu) {
		return <p>No menu found</p>;
	}

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			{activeMenu.Categories.map((category, categoryIndex) => (
				<div key={category.id} className="px-6 md:px-10 mb-4 md:mb-8">
					{/* Category Header */}
					<div className="flex justify-between items-center">
						<div className="md:mb-6 flex flex-row justify-start items-center">
							<button
								onClick={() => toggleCategoryOpen(categoryIndex)}
								className="flex flex-row justify-start items-center"
							>
								<h2 className="text-2xl md:text-4xl font-semibold mr-2 md:mr-5">{category.name}</h2>

								{category.isOpen ? (
									<FaCompressAlt className="w-6 md:w-10 h-10" />
								) : (
									<FaExpandAlt className="w-6 md:w-10 h-10" />
								)}
							</button>
						</div>
						<div>
							<button onClick={() => handleDeleteCategory(category.id)}>
								<MdDelete className="w-6 md:w-10 h-10 mx-1 md:mx-3 text-red-600" />
							</button>
							<button onClick={() => handleCategoryMove(category.id, "down")}>
								<FaChevronDown className="w-6 md:w-10 h-10  mx-1 md:mx-3" />
							</button>
							<button onClick={() => handleCategoryMove(category.id, "up")}>
								<FaChevronUp className="w-6 md:w-10 h-10  mx-1 md:mx-3" />
							</button>
						</div>
					</div>

					{/* Menu Items */}
					<Droppable droppableId={"" + category.id}>
						{(provided) => (
							<div ref={provided.innerRef} {...provided.droppableProps}>
								{(category.MenuItems || [])
									.sort((a, b) => a.order - b.order)
									.map((menuItem, index) =>
										!category.isOpen ? null : (
											<Draggable
												key={menuItem.id}
												draggableId={`item-${menuItem.id}`}
												index={index}
											>
												{(provided) => (
													<div
														className="bg-zinc-800 px-6 py-4 rounded-2xl mb-4"
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
													>
														<div>
															<div className="flex justify-between">
																<div className="flex flex-col md:flex-row items-start">
																	<div className="flex flex-col mr-10">
																		<h3 className="text-2xl font-semibold mb-2">
																			{menuItem.name}
																		</h3>
																		<p className="text-zinc-200 text-xl mb-2">
																			<span className="text-blue-300">
																				Description:{" "}
																			</span>
																			{menuItem.description}
																		</p>
																		<p className="text-zinc-200 text-xl mb-2">
																			<span className="text-blue-300">
																				Food info:{" "}
																			</span>
																			{menuItem.foodInfo}
																		</p>
																		<p className="text-zinc-200 text-xl mb-2">
																			<span className="text-blue-300">
																				Modifier:{" "}
																			</span>
																			{menuItem.modifier}
																		</p>
																	</div>
																	{menuItem.imageUrl && (
																		<img
																			src={menuItem.imageUrl}
																			alt={menuItem.name}
																			className="hidden md:block mt-2 max-h-40  object-contain rounded-lg mr-8"
																		/>
																	)}
																</div>
																<div className="flex flex-col justify-between items-end">
																	<div className="flex flex-col-reverse items-end lg:block">
																		<button
																			onClick={() =>
																				handleDeleteClick(menuItem.id)
																			}
																			className={
																				dashButton +
																				"!border-none !text-red-500 !px-4"
																			}
																		>
																			Delete
																		</button>
																		<button
																			onClick={() => handleEditClick(menuItem)}
																			className={dashButton}
																		>
																			Edit
																		</button>
																	</div>
																	<h2 className="text-zinc-100 text-2xl font-semibold">
																		{Number(menuItem.price).toFixed(2)} €
																	</h2>
																</div>
															</div>
															{menuItem.imageUrl && (
																<img
																	src={menuItem.imageUrl}
																	alt={menuItem.name}
																	className="block md:hidden mt-4 max-h-48 object-contain rounded-lg mx-auto"
																/>
															)}
														</div>
													</div>
												)}
											</Draggable>
										)
									)}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</div>
			))}
		</DragDropContext>
	);
};

export default MenuItemsList;
