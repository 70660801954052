import React from "react";
import PricingCard from "./PricingCard";

const PricingSection = ({ tiers, isYearly, togglePlan }) => (
	<section id="pricing">
		<div className="relative flex-col pt-[380px] md:pt-[230px] max-w-6xl pb-10 w-full mx-auto">
			<div className="flex flex-col md:flex-row justify-between w-full">
				<h2 className="text-5xl font-semibold mb-4 ml-4">Pricing</h2>
				<div className="bg-zinc-800 rounded-full p-1 flex justify-between w-48 ml-4 mt-2 md:m-0">
					<button
						onClick={togglePlan}
						className={`w-1/2 py-2 rounded-full text-sm font-semibold focus:outline-none transition-colors duration-300 ${
							!isYearly ? "bg-gradient-to-bl from-blue-300 to-violet-500 text-white" : "text-gray-600"
						}`}
					>
						Monthly
					</button>
					<button
						onClick={togglePlan}
						className={`w-1/2 py-2 rounded-full text-sm font-semibold focus:outline-none transition-colors duration-300 ${
							isYearly ? "bg-gradient-to-tr from-blue-300 to-violet-500 text-white" : "text-gray-600"
						}`}
					>
						Yearly
					</button>
				</div>
			</div>
			<div className="hidden lg:grid grid-cols-3 px-8 gap-6 place-items-center mb-10 no-scrollbar">
				{Object.entries(tiers).map(([title, { src, price, yearPrice, features }]) => {
					const finalPrice = isYearly ? yearPrice : price;
					const glow = title == "Pro Tier";
					return PricingCard(src, title, features, finalPrice, isYearly, glow);
				})}
			</div>
			<div className="flex lg:hidden flew-row overflow-scroll items-center pb-20 no-scrollbar">
				{Object.entries(tiers).map(([title, { src, price, yearPrice, features }]) => {
					const finalPrice = isYearly ? yearPrice : price;
					const glow = title == "Pro Tier";
					return PricingCard(src, title, features, finalPrice, isYearly, glow);
				})}
			</div>
		</div>
	</section>
);

export default PricingSection;
